import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
        <title>Not found</title>
      </Helmet>

    </main>
  );
};

export default NotFoundPage;
